import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container mt-5" }
const _hoisted_2 = { class: "row g-0 justify-content-md-center" }
const _hoisted_3 = { class: "col-md-3" }
const _hoisted_4 = {
  class: "card contenu",
  title: "Connexion"
}
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_7 = {
  key: 1,
  class: "alert alert-warning",
  role: "alert"
}
const _hoisted_8 = { class: "form-group col-auto" }
const _hoisted_9 = { class: "form-group col-auto" }
const _hoisted_10 = { class: "input-group" }
const _hoisted_11 = ["type"]
const _hoisted_12 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_13 = ["disabled"]

import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useGestionCompteStore } from "@/store/gestion-compte-store";
import { ConfigVerifPassword } from "@/utils/ConfigVerifPassword";


export default /*@__PURE__*/_defineComponent({
  __name: 'FinalisationRecuperationCompte',
  setup(__props) {

const router = useRouter();
const password = ref('');
const courriel = ref('');
const isPaswordValid = ref(false);
const motPasseVisible = ref(false)
const verifPassword = new ConfigVerifPassword()
const modifierMotPasseVisible = () => motPasseVisible.value = !motPasseVisible.value
const gestionCompteStore = useGestionCompteStore()
const isErreurCode = ref(false);
const isErreurRecuperationCompte = ref(false);
const isErreurRecupInfo = ref(false);
const messageErreurRecuperationCompte = ref('')
const messageErreurRecupInfo = ref('')
const route = useRoute()
const code = computed(() => route.params.code)
const isValitationEnabled = ref(true)

onMounted(async () => {
  if (typeof code.value !== 'string') {
    isErreurCode.value = true;
    return;
  }
  let resultatRecup = await gestionCompteStore.donneCourrielIndividuByCode(code.value);
  if (resultatRecup.erreur != null) {
    messageErreurRecupInfo.value = resultatRecup.erreur.message
    return;
  }
  courriel.value = resultatRecup.courriel;
  isErreurRecupInfo.value = false;
});

const evaluationPassword = () => {
  isPaswordValid.value = verifPassword.isPasswordValid(password.value);
}

const recuperationCompte = async () => {
  isValitationEnabled.value = false;
  if (typeof code.value == 'string' && isPaswordValid.value) {
    const resultatRecuperationCompte = await gestionCompteStore.recuperationCompte(code.value, password.value)
    if (resultatRecuperationCompte.erreur != null) {
      messageErreurRecuperationCompte.value = resultatRecuperationCompte.erreur.message
      isErreurRecuperationCompte.value = true;
    } else if (!resultatRecuperationCompte.reussi) {
      isErreurRecuperationCompte.value = true;
      messageErreurRecuperationCompte.value = "La récupération du compte a échoué";
    } else {
      isErreurRecuperationCompte.value = false;
      await router.push({ path: '/' });
    }
  }
  isValitationEnabled.value = true
};



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "card-title" }, "Finalisation de la modification du mot de passe", -1)),
            (isErreurRecupInfo.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(messageErreurRecupInfo.value), 1))
              : _createCommentVNode("", true),
            (isErreurCode.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Le code ne semble pas être formatté correctement "))
              : _createCommentVNode("", true),
            _createElementVNode("form", {
              onSubmit: _withModifiers(recuperationCompte, ["prevent"])
            }, [
              _createElementVNode("div", _hoisted_8, [
                _cache[2] || (_cache[2] = _createElementVNode("label", null, "Email", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  id: "email",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((courriel).value = $event)),
                  type: "email",
                  required: "",
                  disabled: ""
                }, null, 512), [
                  [_vModelText, courriel.value]
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[4] || (_cache[4] = _createElementVNode("label", { for: "password" }, "Mot de passe", -1)),
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("input", {
                    id: "password",
                    type: motPasseVisible.value ? 'text' : 'password',
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                    class: _normalizeClass([isPaswordValid.value ? 'is-valid':'is-invalid', "form-control"]),
                    placeholder: "Mot de passe",
                    onInput: evaluationPassword
                  }, null, 42, _hoisted_11), [
                    [_vModelDynamic, password.value]
                  ]),
                  _createElementVNode("div", { class: "input-group-append" }, [
                    _createElementVNode("button", {
                      class: "btn btn-outline-secondary",
                      type: "button",
                      onClick: modifierMotPasseVisible
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("i", { class: "fas fa-eye" }, null, -1)
                    ]))
                  ])
                ])
              ]),
              (isErreurRecuperationCompte.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(messageErreurRecuperationCompte.value), 1))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-primary",
                disabled: !isPaswordValid.value || !isValitationEnabled.value
              }, "Modifier le mot de passe ", 8, _hoisted_13)
            ], 32)
          ])
        ])
      ])
    ])
  ]))
}
}

})