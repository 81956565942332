import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "nav nav-tabs primary-tabs" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }

import { onMounted } from "vue";
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const storeIndividuConnecte = useIndividuConnecteStore()


const deconnexion = async () => {
  try {
    await storeIndividuConnecte.deconnexion();
    await router.push({ path: '/connexion' });
  } catch (error: unknown) {
    console.log("impossible de se déconnecter");
  }
}

const recupereInfoConnexion = async () => {
  await storeIndividuConnecte.connecterIndividu();
};

// Vérification de la connexion au chargement de l'application
onMounted(() =>
  recupereInfoConnexion()
)

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", _hoisted_2, [
        _createVNode(_component_router_link, {
          class: "nav-link",
          to: "/",
          href: "/"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "fa-solid fa-house" }, null, -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "nav-link",
          to: "/recherche-oeuvre/"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Recherche")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("li", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "nav-link",
          to: "/recherche-atelier/"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Ateliers d'écriture")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("li", _hoisted_5, [
        _createVNode(_component_router_link, {
          class: "nav-link",
          to: "/recherche-auteur/"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Auteurs")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("li", _hoisted_6, [
        _createVNode(_component_router_link, {
          class: "nav-link",
          to: "/recherche-discussion/"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Discussions")
          ])),
          _: 1
        })
      ]),
      (_unref(storeIndividuConnecte).individuConnecte?.id !=null)
        ? (_openBlock(), _createElementBlock("li", {
            key: 0,
            class: "nav-item",
            onClick: _withModifiers(deconnexion, ["prevent"])
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "nav-link" }, [
              _createElementVNode("i", { class: "fa-solid fa-plug-circle-xmark fa-2" })
            ], -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_router_view)
  ], 64))
}
}

})