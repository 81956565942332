import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card m-3" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "m-1" }
const _hoisted_4 = { class: "form-group col-auto" }
const _hoisted_5 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_6 = { class: "form-group col-auto mb-3" }
const _hoisted_7 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_8 = { class: "card m-1" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "form-group col-4" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "modal-dialog" }
const _hoisted_14 = { class: "modal-content" }
const _hoisted_15 = { class: "modal-body" }

import { computed, onMounted, ref } from "vue";

import { useRoute } from "vue-router";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import RecadreurImage from "@/outil-compposant/RecadreurImage.vue";
import { Modal } from 'bootstrap';


export default /*@__PURE__*/_defineComponent({
  __name: 'IndividuConfiguration',
  setup(__props) {

const route = useRoute()
const id = Number(route.params.id);
const modalRef = ref(null);

const storeIndividuPresentation = useIndividuPresentationStore()
const nouveauPseudonyme = ref("")
const nouveauTextePresentation = ref("")
const nouvelleImageContenu = ref("");
const afficheRecadreurImage = ref(false)

const onImageRecadre = (imageRecadree: string) => {
  nouvelleImageContenu.value = imageRecadree;
  afficheRecadreurImage.value = false;
  if(modalRef.value)  Modal.getInstance(modalRef.value)?.hide();
};

const estPseudonymeErreur = computed(() => {
  return nouveauPseudonyme.value.length == 0 || nouveauPseudonyme.value.length > 30
})
const estPresentationErreur = computed(() => {
  if (!nouveauTextePresentation.value) return false;
  return nouveauTextePresentation.value.length > 500
})
const modifierInfoPub = async () => {
  await storeIndividuPresentation.modifier(nouveauPseudonyme.value, nouveauTextePresentation.value, nouvelleImageContenu.value);
}


onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    if (storeIndividuPresentation.individuSelectionne) {
      nouveauPseudonyme.value = storeIndividuPresentation.individuSelectionne.pseudonyme
      nouveauTextePresentation.value = storeIndividuPresentation.individuSelectionne.presentation
      nouvelleImageContenu.value = storeIndividuPresentation.individuSelectionne.imagePresentation
    }
  } catch (error) {
    console.log("OnMounted Error");
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("h5", { class: "card-title mb-3" }, "Vos informations publiques", -1)),
        _createElementVNode("form", {
          onSubmit: _withModifiers(modifierInfoPub, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createElementVNode("label", null, "Changer le pseudonyme", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((nouveauPseudonyme).value = $event))
              }, null, 512), [
                [_vModelText, nouveauPseudonyme.value]
              ]),
              (estPseudonymeErreur.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Minimum un caractère, maximum 30"))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[3] || (_cache[3] = _createElementVNode("label", null, "Changer le texte de présentation", -1)),
            _createVNode(_unref(QuillEditor), {
              class: "m-1 rounded-1",
              content: nouveauTextePresentation.value,
              "onUpdate:content": _cache[1] || (_cache[1] = ($event: any) => ((nouveauTextePresentation).value = $event)),
              theme: "snow",
              contentType: "html"
            }, null, 8, ["content"]),
            (estPresentationErreur.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Maximum 500 caractères en comptant ceux servant à la mise en forme"))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[4] || (_cache[4] = _createElementVNode("label", null, "Ajoutez ou modifiez votre image de profil", -1)),
                _cache[5] || (_cache[5] = _createElementVNode("button", {
                  type: "button",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#telechargementImageModal",
                  class: "btn btn-secondary m-3"
                }, "Téléchargement ", -1)),
                (nouvelleImageContenu.value)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: nouvelleImageContenu.value,
                      class: "img-fluid rounded-1",
                      alt: "Nouvelle image de profil"
                    }, null, 8, _hoisted_11))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary",
            disabled: estPseudonymeErreur.value || estPresentationErreur.value
          }, " Sauvegarder les modifications ", 8, _hoisted_12)
        ], 32)
      ])
    ]),
    _createElementVNode("div", {
      id: "telechargementImageModal",
      class: "modal",
      tabindex: "-1",
      ref_key: "modalRef",
      ref: modalRef
    }, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("h5", { class: "modal-title" }, "Création de compte"),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(RecadreurImage, {
              "largeur-stencil": "400",
              "hauteur-stencil": "400",
              onRecadre: onImageRecadre
            })
          ])
        ])
      ])
    ], 512)
  ], 64))
}
}

})