<template>
  <div class="card m-3">
    <div class="card-body">
      <h5 class="card-title mb-3">Vos informations publiques</h5>
      <form @submit.prevent="modifierInfoPub">
        <div class="m-1">
          <div class="form-group col-auto">
            <label>Changer le pseudonyme</label>
            <input type="text" class="form-control"
                   v-model="nouveauPseudonyme" />
            <div v-if="estPseudonymeErreur" class="alert alert-danger" role="alert">Minimum un caractère, maximum 30</div>
          </div>
        </div>
        <div class="form-group col-auto mb-3">
          <label>Changer le texte de présentation</label>
          <quill-editor class="m-1 rounded-1" v-model:content="nouveauTextePresentation" theme="snow" contentType="html" />
          <div v-if="estPresentationErreur" class="alert alert-danger" role="alert">Maximum 500 caractères en comptant ceux servant à la mise en forme</div>
        </div>
        <div class="card m-1">
          <div class="card-body">
            <div class="form-group col-4">
              <label>Ajoutez ou modifiez votre image de profil</label>
              <button type="button" data-bs-toggle="modal" data-bs-target="#telechargementImageModal"
                      class="btn btn-secondary m-3">Téléchargement
              </button>

              <img v-if="nouvelleImageContenu"
                   :src="nouvelleImageContenu" class="img-fluid rounded-1"
                   alt="Nouvelle image de profil">

            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary" :disabled="estPseudonymeErreur || estPresentationErreur">
          Sauvegarder les modifications
        </button>
      </form>
    </div>
  </div>
  <!-- modal téléchargement-->
  <div id="telechargementImageModal" class="modal" tabindex="-1" ref="modalRef">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Création de compte</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <RecadreurImage largeur-stencil="400" hauteur-stencil="400" @recadre="onImageRecadre" />
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal téléchargement-->
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

const route = useRoute()
const id = Number(route.params.id);
import { useRoute } from "vue-router";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import RecadreurImage from "@/outil-compposant/RecadreurImage.vue";
import { Modal } from 'bootstrap';

const modalRef = ref(null);

const storeIndividuPresentation = useIndividuPresentationStore()
const nouveauPseudonyme = ref("")
const nouveauTextePresentation = ref("")
const nouvelleImageContenu = ref("");
const afficheRecadreurImage = ref(false)

const onImageRecadre = (imageRecadree: string) => {
  nouvelleImageContenu.value = imageRecadree;
  afficheRecadreurImage.value = false;
  if(modalRef.value)  Modal.getInstance(modalRef.value)?.hide();
};

const estPseudonymeErreur = computed(() => {
  return nouveauPseudonyme.value.length == 0 || nouveauPseudonyme.value.length > 30
})
const estPresentationErreur = computed(() => {
  if (!nouveauTextePresentation.value) return false;
  return nouveauTextePresentation.value.length > 500
})
const modifierInfoPub = async () => {
  await storeIndividuPresentation.modifier(nouveauPseudonyme.value, nouveauTextePresentation.value, nouvelleImageContenu.value);
}


onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    if (storeIndividuPresentation.individuSelectionne) {
      nouveauPseudonyme.value = storeIndividuPresentation.individuSelectionne.pseudonyme
      nouveauTextePresentation.value = storeIndividuPresentation.individuSelectionne.presentation
      nouvelleImageContenu.value = storeIndividuPresentation.individuSelectionne.imagePresentation
    }
  } catch (error) {
    console.log("OnMounted Error");
  }
})
</script>


<style scoped lang="scss">
</style>