import { createApp } from "vue";
import App from "./App.vue";
import index from "./router";
import { createPinia } from 'pinia'

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap'
const pinia = createPinia()

createApp(App).use(pinia).use(index).mount("#app");
