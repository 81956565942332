<template>
  <div class="container mt-5">
    <div class="row g-0 justify-content-md-center">
      <div class="col-md-3">
        <div class="card contenu" title="Connexion">
          <div class="card-body">
            <h3 class="card-title">Finalisation de la modification du mot de passe</h3>
            <div v-if="isErreurRecupInfo" class="alert alert-danger" role="alert">
              {{ messageErreurRecupInfo }}
            </div>
            <div v-if="isErreurCode" class="alert alert-warning" role="alert">
              Le code ne semble pas être formatté correctement
            </div>
            <form @submit.prevent="recuperationCompte">
              <div class="form-group col-auto">
                <label>Email</label>
                <input class="form-control" id="email" v-model="courriel" type="email" required disabled/>
              </div>
              <div class="form-group col-auto">
                <label for="password">Mot de passe</label>
                <div class="input-group">
                  <input id="password" :type="motPasseVisible ? 'text' : 'password'"
                         v-model="password" :class="isPaswordValid ? 'is-valid':'is-invalid'"
                         class="form-control" placeholder="Mot de passe" @input="evaluationPassword">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" @click="modifierMotPasseVisible">
                      <i class="fas fa-eye"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="isErreurRecuperationCompte" class="alert alert-danger" role="alert">
                {{ messageErreurRecuperationCompte }}
              </div>
              <button type="submit" class="btn btn-primary" :disabled="!isPaswordValid || !isValitationEnabled">Modifier
                le mot de passe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useGestionCompteStore } from "@/store/gestion-compte-store";
import { ConfigVerifPassword } from "@/utils/ConfigVerifPassword";

const router = useRouter();
const password = ref('');
const courriel = ref('');
const isPaswordValid = ref(false);
const motPasseVisible = ref(false)
const verifPassword = new ConfigVerifPassword()
const modifierMotPasseVisible = () => motPasseVisible.value = !motPasseVisible.value
const gestionCompteStore = useGestionCompteStore()
const isErreurCode = ref(false);
const isErreurRecuperationCompte = ref(false);
const isErreurRecupInfo = ref(false);
const messageErreurRecuperationCompte = ref('')
const messageErreurRecupInfo = ref('')
const route = useRoute()
const code = computed(() => route.params.code)
const isValitationEnabled = ref(true)

onMounted(async () => {
  if (typeof code.value !== 'string') {
    isErreurCode.value = true;
    return;
  }
  let resultatRecup = await gestionCompteStore.donneCourrielIndividuByCode(code.value);
  if (resultatRecup.erreur != null) {
    messageErreurRecupInfo.value = resultatRecup.erreur.message
    return;
  }
  courriel.value = resultatRecup.courriel;
  isErreurRecupInfo.value = false;
});

const evaluationPassword = () => {
  isPaswordValid.value = verifPassword.isPasswordValid(password.value);
}

const recuperationCompte = async () => {
  isValitationEnabled.value = false;
  if (typeof code.value == 'string' && isPaswordValid.value) {
    const resultatRecuperationCompte = await gestionCompteStore.recuperationCompte(code.value, password.value)
    if (resultatRecuperationCompte.erreur != null) {
      messageErreurRecuperationCompte.value = resultatRecuperationCompte.erreur.message
      isErreurRecuperationCompte.value = true;
    } else if (!resultatRecuperationCompte.reussi) {
      isErreurRecuperationCompte.value = true;
      messageErreurRecuperationCompte.value = "La récupération du compte a échoué";
    } else {
      isErreurRecuperationCompte.value = false;
      await router.push({ path: '/' });
    }
  }
  isValitationEnabled.value = true
};


</script>