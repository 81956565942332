<template>
  <form @submit.prevent="recuperation" novalidate>
    <div class="form-group col-auto">
      <label class="form-label" for="courriel">Entrez votre email</label>
      <input id="courriel" class="form-control" :class="isCourrielValide? 'is-valid':'is-invalid'" v-model="courriel" type="email" required  @input="evaluationCourriel" />
      <div class="valid-feedback">
        Courriel bien formé
      </div>
      <div class="invalid-feedback">
        Courriel mal formé ou vide
      </div>
    </div>
    <div v-if="afficherRecuperationOk" class="alert alert-primary" role="alert">
      Un message de récupération va vous être envoyez, cliquez sur le lien joint
    </div>
    <div v-if="afficherRecuperationEchec" class="alert alert-warning" role="alert">
      Erreur, peut-être n'y at-il pas d'e-mail pour ce compte ?
    </div>
    <div v-if="isErreurRecuperation" class="alert alert-danger" role="alert">
      {{ messageErreur }}
    </div>

    <button type="submit" class="btn btn-primary" :disabled="!isCourrielValide || !isValitationEnabled">
      Créer
    </button>
  </form>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { ref } from "vue";
import { useGestionCompteStore } from "@/store/gestion-compte-store";

const afficherRecuperationOk = ref(false);
const afficherRecuperationEchec = ref(false);
const isErreurRecuperation = ref(false);
const messageErreur = ref('')
const gestionCompteStore = useGestionCompteStore()
const isCourrielValide = ref(false)
const isValitationEnabled = ref(true)

const courriel = ref("")

const evaluationCourriel = () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  isCourrielValide.value = emailPattern.test(courriel.value) && courriel.value.length < 100
}

const recuperation = async () => {
  isValitationEnabled.value =false
  afficherRecuperationOk.value = false
  afficherRecuperationEchec.value = false
  isErreurRecuperation.value = false
  const resultat = await gestionCompteStore.demandeRecuperationCompte(courriel.value);
  if (resultat.erreur != null) {
    messageErreur.value = resultat.erreur.message
    isErreurRecuperation.value = true
    afficherRecuperationOk.value = false
    afficherRecuperationEchec.value = false
  } else if (resultat.reussi == true) {
    afficherRecuperationOk.value = true
    afficherRecuperationEchec.value = false
  } else {
    afficherRecuperationOk.value = false
    afficherRecuperationEchec.value = true
  }
  isValitationEnabled.value = true
};
</script>
