<template>
  <form @submit.prevent="creation" class="row g-3 needs-validation" novalidate>
    <div class="form-group col-auto">
      <label class="form-label" for="courriel">Entrez votre email</label>
      <input id="courriel" class="form-control" :class="isCourrielValide? 'is-valid':'is-invalid'" v-model="courriel" type="email" required  @input="evaluationEmail" />
      <div class="valid-feedback">
        Courriel bien formé
      </div>
      <div class="invalid-feedback">
        Courriel mal formé ou vide
      </div>
    </div>
    <div class="form-group col-auto">
      <label>Entrez votre date de naissance, vous devez avoir 15 ans minimum</label>
      <input id="dateNaissance" class="form-control" :class="isDateValide? 'is-valid':'is-invalid'" v-model="dateNaissance" type="date" required  @input="evaluationDate" />
      <div class="valid-feedback">
        C'est bon vous avez plus de quinze ans
      </div>
      <div class="invalid-feedback">
        La majorité numérique est recquise
      </div>
      <div v-if="afficherCreationOk" class="alert alert-primary" role="alert">
        Le courriel est créé
      </div>
      <div v-if="afficherCreationEchec" class="alert alert-warning" role="alert">
        L'e-mail saisit existe déjà dans nos bases
      </div>
      <div v-if="isErreurCreation" class="alert alert-danger" role="alert">
        {{ messageErreurCreation }}
      </div>
      <button type="submit"  class="btn btn-primary" :disabled="!isValitationEnabled || !isDateValide || !isCourrielValide">Créer le compte</button>
    </div>

  </form>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { Ref, ref, watch } from "vue";
import { useGestionCompteStore } from "@/store/gestion-compte-store";

const afficherCreationOk = ref(false);
const afficherCreationEchec = ref(false);
const isErreurCreation = ref(false);
const messageErreurCreation = ref("")
const isDateValide = ref(false);
const courriel = ref("");
const isCourrielValide = ref(false);
const isValitationEnabled = ref(true);
const gestionCompteStore = useGestionCompteStore()

const moinsQuinzeAns = () => {
  let maintenant = new Date();
  maintenant.setHours(0, 0, 0, 0);
  return new Date(maintenant.getTime() - 15 * 365 * 24 * 3600 * 1000)
}
const dateNaissance: Ref<null | string> = ref(null)
const isPlusQuinzeAns = () => {
  const dateNaiss = new Date(dateNaissance.value);
  if(dateNaiss < new Date('01-01-1900')) return false;
  return dateNaiss < new Date(moinsQuinzeAns());
}
const evaluationEmail = () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  isCourrielValide.value = emailPattern.test(courriel.value) && courriel.value.length < 100;
  console.log(isCourrielValide.value)
}
const evaluationDate = () => {
  isDateValide.value = dateNaissance.value!=null && isPlusQuinzeAns()
  console.log("evaluationDate")
  console.log(isDateValide.value)
}

const creation = async () => {
  isValitationEnabled.value = false
  afficherCreationOk.value = false
  if(isDateValide.value && isCourrielValide.value && dateNaissance.value!=null) {
    const resultat = await gestionCompteStore.creationPreIndividu(courriel.value, dateNaissance.value);
    if (resultat.erreur != null) {
      isErreurCreation.value = true
      afficherCreationOk.value = false
      afficherCreationEchec.value = false
      messageErreurCreation.value = resultat.erreur.message
    } else if (!resultat.reussi) {
      afficherCreationOk.value = false
      afficherCreationEchec.value = true
    } else {
      afficherCreationOk.value = true
      afficherCreationEchec.value = false
    }
  }
  isValitationEnabled.value= true
};
watch(dateNaissance, evaluationDate)
</script>
