<template>
  <ul class="nav nav-tabs primary-tabs">
    <li class="nav-item">
      <router-link class="nav-link" to="/" href="/"><i class='fa-solid fa-house'></i></router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/recherche-oeuvre/">Recherche</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/recherche-atelier/">Ateliers d'écriture</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/recherche-auteur/">Auteurs</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/recherche-discussion/">Discussions</router-link>
    </li>
    <li class="nav-item" v-if="storeIndividuConnecte.individuConnecte?.id !=null" @click.prevent="deconnexion">
      <div class="nav-link">
        <i class='fa-solid fa-plug-circle-xmark fa-2'></i>
      </div>
    </li>
  </ul>
  <router-view />
</template>


<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$primary-color: $primary;
$primary-active-color: darken($primary, 10%);
$primary-hover-color: lighten($primary, 5%);


.primary-tabs {
  width: 100vw; // S'étend sur toute la largeur de la fenêtre
  margin-left: calc(-50vw + 50%); // Compense le décalage dû au centrage par défaut
  background-color: $primary-color; // Couleur de fond
  display: flex;
  .nav-item, .nav-item > .nav-link {
    background-color: $primary-color; // Couleur de fond primary
    color: white;
    border: 1px solid $primary-color; // Bordure en accord avec la couleur primaire

    &:hover {
      background-color: $primary-hover-color; // Couleur au survol
      color: white;
    }

    &.active {
      background-color: $primary-active-color; // Couleur pour l'onglet actif
      color: white;
      border-bottom-color: transparent; // Effet de transition fluide
    }
  }
}
</style>

<script setup lang="ts">
import { onMounted } from "vue";
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import router from "@/router";

const storeIndividuConnecte = useIndividuConnecteStore()


const deconnexion = async () => {
  try {
    await storeIndividuConnecte.deconnexion();
    await router.push({ path: '/connexion' });
  } catch (error: unknown) {
    console.log("impossible de se déconnecter");
  }
}

const recupereInfoConnexion = async () => {
  await storeIndividuConnecte.connecterIndividu();
};

// Vérification de la connexion au chargement de l'application
onMounted(() =>
  recupereInfoConnexion()
)
</script>
