import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card m-3" }
const _hoisted_2 = { class: "row g-0" }
const _hoisted_3 = { class: "col-md-3" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "fa-solid fa-user fa-3x",
  style: {"width":"150px","height":"150px","display":"flex","justify-content":"center","align-items":"center"},
  "aria-hidden": "true"
}
const _hoisted_6 = { class: "col-md-8" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "card-title mb-3" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "nav nav-tabs" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "nav-item" }
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = { class: "nav-item" }
const _hoisted_15 = { class: "nav-item" }
const _hoisted_16 = {
  key: 0,
  class: "nav-item"
}

import { useRoute } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'IndividuProfil',
  setup(__props) {

const route = useRoute()
const id = Number(route.params.id);

const storeIndividuConnecte = useIndividuConnecteStore()
const storeIndividuPresentation = useIndividuPresentationStore()

const isIndividuConnecte = ref(false)


onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    isIndividuConnecte.value = storeIndividuConnecte.individuConnecte?.id === id
  } catch (error) {
    console.log("OnMounted Error");
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_unref(storeIndividuPresentation).individuSelectionne?.imagePresentation)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _unref(storeIndividuPresentation).individuSelectionne?.imagePresentation,
                style: {"max-width":"150px"},
                class: "img-fluid rounded-start",
                alt: "Image de profile"
              }, null, 8, _hoisted_4))
            : (_openBlock(), _createElementBlock("i", _hoisted_5))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h5", _hoisted_8, _toDisplayString(_unref(storeIndividuPresentation).individuSelectionne?.pseudonyme), 1),
            _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("p", {
              class: "card-text",
              innerHTML: _unref(storeIndividuPresentation).individuSelectionne?.presentation
            }, null, 8, _hoisted_9)
          ])
        ])
      ])
    ]),
    _createElementVNode("ul", _hoisted_10, [
      _createElementVNode("li", _hoisted_11, [
        _createVNode(_component_router_link, {
          class: "nav-link",
          to: '/individu/'+_unref(id)+'/texte/'
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Textes")
          ])),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("li", _hoisted_12, [
        _createVNode(_component_router_link, {
          class: "nav-link",
          to: '/individu/'+_unref(id)+'/lecture/'
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Lectures")
          ])),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("li", _hoisted_13, [
        _createVNode(_component_router_link, {
          class: "nav-link",
          to: '/individu/'+_unref(id)+'/atelier/'
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Ateliers")
          ])),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("li", _hoisted_14, [
        _createVNode(_component_router_link, {
          class: "nav-link",
          to: '/individu/'+_unref(id)+'/discussion/'
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Discussions")
          ])),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("li", _hoisted_15, [
        _createVNode(_component_router_link, {
          class: "nav-link",
          to: '/individu/'+_unref(id)+'/bibliotheque/'
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Bibliothèque")
          ])),
          _: 1
        }, 8, ["to"])
      ]),
      (isIndividuConnecte.value)
        ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: '/individu/'+_unref(id)+'/configuration/'
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("i", { class: "fa-solid fa-gear" }, null, -1)
              ])),
              _: 1
            }, 8, ["to"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_router_view)
  ], 64))
}
}

})