import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form-group col-auto" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-primary",
  role: "alert"
}
const _hoisted_3 = {
  key: 1,
  class: "alert alert-warning",
  role: "alert"
}
const _hoisted_4 = {
  key: 2,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_5 = ["disabled"]

import { ref } from "vue";
import { useGestionCompteStore } from "@/store/gestion-compte-store";


export default /*@__PURE__*/_defineComponent({
  __name: 'DemandeRecuperationCompte',
  setup(__props) {

const afficherRecuperationOk = ref(false);
const afficherRecuperationEchec = ref(false);
const isErreurRecuperation = ref(false);
const messageErreur = ref('')
const gestionCompteStore = useGestionCompteStore()
const isCourrielValide = ref(false)
const isValitationEnabled = ref(true)

const courriel = ref("")

const evaluationCourriel = () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  isCourrielValide.value = emailPattern.test(courriel.value) && courriel.value.length < 100
}

const recuperation = async () => {
  isValitationEnabled.value =false
  afficherRecuperationOk.value = false
  afficherRecuperationEchec.value = false
  isErreurRecuperation.value = false
  const resultat = await gestionCompteStore.demandeRecuperationCompte(courriel.value);
  if (resultat.erreur != null) {
    messageErreur.value = resultat.erreur.message
    isErreurRecuperation.value = true
    afficherRecuperationOk.value = false
    afficherRecuperationEchec.value = false
  } else if (resultat.reussi == true) {
    afficherRecuperationOk.value = true
    afficherRecuperationEchec.value = false
  } else {
    afficherRecuperationOk.value = false
    afficherRecuperationEchec.value = true
  }
  isValitationEnabled.value = true
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _withModifiers(recuperation, ["prevent"]),
    novalidate: ""
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("label", {
        class: "form-label",
        for: "courriel"
      }, "Entrez votre email", -1)),
      _withDirectives(_createElementVNode("input", {
        id: "courriel",
        class: _normalizeClass(["form-control", isCourrielValide.value? 'is-valid':'is-invalid']),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((courriel).value = $event)),
        type: "email",
        required: "",
        onInput: evaluationCourriel
      }, null, 34), [
        [_vModelText, courriel.value]
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "valid-feedback" }, " Courriel bien formé ", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "invalid-feedback" }, " Courriel mal formé ou vide ", -1))
    ]),
    (afficherRecuperationOk.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Un message de récupération va vous être envoyez, cliquez sur le lien joint "))
      : _createCommentVNode("", true),
    (afficherRecuperationEchec.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Erreur, peut-être n'y at-il pas d'e-mail pour ce compte ? "))
      : _createCommentVNode("", true),
    (isErreurRecuperation.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(messageErreur.value), 1))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      type: "submit",
      class: "btn btn-primary",
      disabled: !isCourrielValide.value || !isValitationEnabled.value
    }, " Créer ", 8, _hoisted_5)
  ], 32))
}
}

})