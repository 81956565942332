<template>
  <div class="div-cropper">
    <p>Zoomez dézoomez avec la molette, déplacez l'image de fond en la glissant</p>
    <!--<cropper ref="cropper" class="cropper" :src="image.src"   :stencil-size="stencilDimensions"/>-->
    <cropper ref="cropper" class="cropper" :src="image.src"   :stencil-props="{
		aspectRatio: ratio,
		movable: true,
		resizable: true
	}"/>
    <div class="button-wrapper">
      <button class="button" @click="file.click()">
        <input
          type="file"
          ref="file"
          @change="uploadImage($event)"
          accept="image/jpeg,image/png,image/webp"
        />
        Upload image
      </button>
      <button class="button" @click="cropImage">Recadrer l'image</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onUnmounted } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default defineComponent({
  components: {
    Cropper,
  },
  props: {
    largeurStencil: {
      type: Number,
      required: true,
    },
    hauteurStencil: {
      type: Number,
      required: true,
    },
  },
  setup(props,{emit}) {
    const image = reactive({
      src:
        "",
      type: "",
    });


    const cropper = ref();

    const file = ref();

    const ratio = props.hauteurStencil/ props.largeurStencil

    const cropImage = () => {
      if (cropper.value) {
        // Récupérer les dimensions de la zone croppée
        const coordinates = cropper.value.coordinates;
        const croppedWidth = coordinates.width;
        const croppedHeight = coordinates.height;

        // Utiliser les dimensions du crop si elles sont inférieures au stencil
        const finalWidth = Math.min(croppedWidth, props.largeurStencil);
        const finalHeight = Math.min(croppedHeight, props.hauteurStencil);

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          console.error('Impossible de récupérer le contexte 2D du canvas.');
          return;
        }

        // Définir les dimensions du canvas
        canvas.width = finalWidth;
        canvas.height = finalHeight;

        // Créer une image pour dessiner sur le canvas
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Pour éviter les problèmes de CORS avec les blobs
        img.src = image.src;

        img.onload = () => {
          // Dessiner l'image sur le canvas
          ctx.drawImage(
            img,
            coordinates.left,
            coordinates.top,
            coordinates.width,
            coordinates.height,
            0,
            0,
            finalWidth,
            finalHeight
          );
          // Convertir le canvas en image encodée en base64
          const croppedImage = canvas.toDataURL('image/webp',0.8);

          // Émettre l'événement vers le parent avec l'image encodée en base64
          emit('recadre', croppedImage);
        }
      }
    }


    const uploadImage = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target && target.files && target.files[0]) {
        const file = target.files[0];

        // Si une ancienne image existe, libérer l'URL correspondante
        if (image.src) {
          URL.revokeObjectURL(image.src);
        }

        // Créer une nouvelle URL pour l'image et l'assigner
        image.src = URL.createObjectURL(file);
        image.type = file.type;
      }
    };

    onUnmounted(() => {
      if (image.src) {
        URL.revokeObjectURL(image.src);
      }
    });

    return {
      image,
      cropper,
      file,
      uploadImage,
      cropImage,
      ratio,
    };
  },
});
</script>

<style lang="scss">
.div-cropper {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.cropper {
  max-width: 600px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
  background: #151515;
  cursor: pointer;
  transition: background 0.5s;
  border: none;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
  &:hover {
    background: #2F2F2F;
  }
  input {
    display: none;
  }
}
</style>
