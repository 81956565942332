<template>
  <div class="card m-3">
    <div class="row g-0">
      <div class="col-md-3">
        <img v-if="storeIndividuPresentation.individuSelectionne?.imagePresentation"
             :src="storeIndividuPresentation.individuSelectionne?.imagePresentation" style="max-width: 150px;" class="img-fluid rounded-start" alt="Image de profile">
        <i v-else class="fa-solid fa-user fa-3x"  style="width: 150px; height: 150px; display: flex; justify-content: center; align-items: center;" aria-hidden="true"></i>
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h5 class="card-title mb-3" >{{storeIndividuPresentation.individuSelectionne?.pseudonyme}}</h5>
          <hr/>
          <p class="card-text" v-html="storeIndividuPresentation.individuSelectionne?.presentation"></p>
        </div>
      </div>
    </div>

  </div>
  <ul class="nav nav-tabs">
    <li class="nav-item"><router-link class="nav-link"  :to="'/individu/'+id+'/texte/'">Textes</router-link>  </li>
    <li class="nav-item"><router-link class="nav-link"  :to="'/individu/'+id+'/lecture/'">Lectures</router-link>  </li>
    <li class="nav-item"><router-link class="nav-link"  :to="'/individu/'+id+'/atelier/'">Ateliers</router-link>  </li>
    <li class="nav-item"><router-link class="nav-link"  :to="'/individu/'+id+'/discussion/'">Discussions</router-link>  </li>
    <li class="nav-item"><router-link class="nav-link"  :to="'/individu/'+id+'/bibliotheque/'">Bibliothèque</router-link></li>
    <li class="nav-item" v-if="isIndividuConnecte">
      <router-link class="nav-link"  :to="'/individu/'+id+'/configuration/'">
        <i class="fa-solid fa-gear" ></i>
      </router-link>
    </li>
  </ul>
  <router-view></router-view>
</template>

<style scoped lang="scss">

</style>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { onMounted, ref } from "vue";

const route = useRoute()
const id = Number(route.params.id);

const storeIndividuConnecte = useIndividuConnecteStore()
const storeIndividuPresentation = useIndividuPresentationStore()

const isIndividuConnecte = ref(false)


onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    isIndividuConnecte.value = storeIndividuConnecte.individuConnecte?.id === id
  } catch (error) {
    console.log("OnMounted Error");
  }
})
</script>