import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "div-cropper" }
const _hoisted_2 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cropper = _resolveComponent("cropper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("p", null, "Zoomez dézoomez avec la molette, déplacez l'image de fond en la glissant", -1)),
    _createVNode(_component_cropper, {
      ref: "cropper",
      class: "cropper",
      src: _ctx.image.src,
      "stencil-props": {
		aspectRatio: _ctx.ratio,
		movable: true,
		resizable: true
	}
    }, null, 8, ["src", "stencil-props"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.file.click()))
      }, [
        _createElementVNode("input", {
          type: "file",
          ref: "file",
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.uploadImage($event))),
          accept: "image/jpeg,image/png,image/webp"
        }, null, 544),
        _cache[3] || (_cache[3] = _createTextVNode(" Upload image "))
      ]),
      _createElementVNode("button", {
        class: "button",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cropImage && _ctx.cropImage(...args)))
      }, "Recadrer l'image")
    ])
  ]))
}